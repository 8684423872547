import Vue from 'vue';
import Component from 'vue-class-component';
import {Inject} from 'vue-property-decorator';
import {Tag} from '@bakerware/document-creation-admin';
import {TagService} from "@ncnp-mono/utils";
import html from './template-manager.component.html';

@Component({
    template: html,
})

export class TemplateManagerComponent extends Vue {
    @Inject()
    tagService!: TagService;

    public tags: Tag[] = [];

    public async beforeMount(): Promise<void> {
        this.tags = await this.tagService.getAllTags();
    }

    public onMessage(message: string): void {
        this.$snackbar.info(message);
    }
}
